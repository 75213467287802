import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import type { organization_type, user_role } from "dcp-types"
import { getSession } from "next-auth/react"

export const LibConstants = {
  defaultLimit: 10,
}

/**
 * @deprecated - TODO: remove all legacy supabase types
 */
export type PostgrestError = {
  message: string
  details: string
  hint: string
  code: string
}

/**
 * @deprecated - TODO: remove all legacy supabase types
 */
export interface AuthError extends Error {
  /**
   * Error code associated with the error. Most errors coming from
   * HTTP responses will have a code, though some errors that occur
   * before a response is received will not have one present. In that
   * case {@link #status} will also be undefined.
   */
  code: string | undefined

  /** HTTP status code that caused the error. */
  status: number | undefined
}

/**
 * @deprecated - TODO: remove all legacy supabase types
 */
export type PostgrestSingleResponse<T> =
  | {
      status: number
      statusText: string
      error: null
      data: T
      count: number | null
    }
  | {
      status: number
      statusText: string
      error: PostgrestError
      data: null
      count: null
    }

/**
 * @deprecated - TODO: remove all legacy supabase types
 */
// biome-ignore lint/suspicious/noExplicitAny: in TS there seems no way to overcome any in this generic case
export type AwaitedReturn<T extends (...args: any) => any> = Omit<
  Awaited<ReturnType<T>>,
  "error"
>

// biome-ignore lint/suspicious/noExplicitAny: in TS there seems no way to overcome any in this generic case
export type AwaitedData<T extends (...args: any) => any> = Awaited<
  ReturnType<T>
>["data"]

export interface UserData {
  id?: string
  organizationId: string
  userId: string
  role?: "authenticated" | "system_admin" | string | undefined
  type?: organization_type
  email?: string
  roles?: user_role[]
}

export type APIUserData = UserData | { organizationId: string; userId: null }

export const useGetUserSessionData = (
  options: Omit<
    UseQueryOptions<AwaitedReturn<typeof getUserSessionData>, PostgrestError>,
    "queryKey" | "queryFn"
  > = {},
) => {
  return useQuery({
    queryKey: ["auth.getUserSessionData"],
    queryFn: () => getUserSessionData(),
    ...options,
  })
}

/**
 * This function is used to get data from the JWT
 * @returns
 */
export const getUserSessionData = async (): Promise<
  PostgrestSingleResponse<UserData>
> => {
  // This call retrieves data from the JWT
  const result = await getSession()

  if (!result) {
    return {
      data: null,
      error: {
        message: "Missing data in JWT",
        code: "missing-jwt",
        details: "",
        hint: "",
      },
      count: null,
      status: 401,
      statusText: "Illegal Auth State",
    }
  }

  return {
    data: result.user as UserData,
    count: 1,
    status: 200,
    statusText: "OK",
    error: null,
  }
}
