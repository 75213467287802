"use client"

import { type BoxProps, Box as MuiBox } from "@mui/material"
import { forwardRef } from "react"

export const Box = forwardRef<unknown, BoxProps<"div", unknown>>(
  // FIXME: the TS error is reported here https://stackoverflow.com/questions/68692230/ts-expression-produces-a-union-type-that-is-too-complex-to-represent-with-materi
  // probably related to conflicts with @react-three/fiber TS definitions
  //  --CANNOT BE USED HERE: @ts-expect-error
  // @ts-ignore
  (props, ref) => <MuiBox {...props} ref={ref} />,
)
Box.displayName = "Box"
